const suggester = (function() {
  let state = {
    suggestions: []
  }

  let xhr = null;
  
  function _suggester(string) {
    if (string.length > 2) {
      if (xhr) {
        xhr.abort();
      }
      
      if (!string.length) {
        _renderSuggester()
      } else {
        string = string.trim();
        console.log(string);
        xhr = $.ajax({
          url: SITE_URL + 'suggester?q=' + string,
          method: 'GET',
          success: function(response) {
            response = JSON.parse(response);
            if (response.output) {
              let result = response.output.suggest.mySuggester[string.trim()]
              console.log(result);
              state.suggestions = result.suggestions;
              _renderSuggester(result)
            } else {
              state.suggestions = [];
              _renderSuggester()
            }
          }
        })
      }
    } else {
      _renderSuggester()
    }
  }

  function _renderSuggester(result = {}) {
    if (result.numFound && result.numFound > 0) {
      result.suggestions.sort((a,b) => {
        return b.weight - a.weight;
      }) 
      let suggestions = result.suggestions.map(s => {
        return `
          <span>${s.term}</span>
        `
      }).join('');
      $('.suggester').html(suggestions);
      $('.suggester').show();

    } else {
      $('.suggester').html('');
      $('.suggester').hide();
    }
  }

  function _insertSuggestion(suggestion, keep = false) {
    let val = $('#search').val().split(' ');
    if (val.length > 1) {
      val.splice(-1, 1);
      val = val.join(' ') + ' ';
    } else {
      val = '';
    }
    $('#search').val(val + suggestion).focus();
    if (!keep) {
      _renderSuggester()
    }
  }

  function _selectSuggestion(keyCode) {
    let direction = keyCode == 40 ? 1 : -1;
    let words = Array.from($('.suggester span')).map(s => $(s).text());

    let input = $('#search').val().trim().split(' ');
    let last = input[input.length - 1];
    let current = words.findIndex(w => last === w.trim());

    let newWord = '';
    if (current > -1) {
      if ((current + direction) > words.length - 1) {
        newWord = words[0];
      } else if ((current + direction) < 0) {
        newWord = words[(words.length - 1)];
      } else {
        newWord = words[(current + direction)];
      }
    } else {
      newWord = words[(direction > 0 ? 0 : (words.length - 1))];
    }
    _insertSuggestion(newWord, true)
  }

  function _setupListeners() {
    $('body').click(function(e) {
      _renderSuggester()
    })

    $('#search').on('keyup', function(e) {
      if (e.keyCode != 40 && e.keyCode != 38) {
        let string = $(this).val();
        if (string.split(' ').length > 1) {
          string = string.split(' ')[string.split(' ').length - 1];
        }
        if (string.length) {
          _suggester(string);
        }
      } else {
        if (state.suggestions.length) {
          e.preventDefault();
          this.selectionStart = $(this).val().length;
          if ($(this).val().length) {
            _selectSuggestion(e.keyCode);
          }
        }
      }
    })

    $('.suggester').click(function(e) {
      if ($(e.target).is('span')) {
        _insertSuggestion($(e.target).text());
      }
    })
  }
  return {
    init: () => {
      _setupListeners();
    }
  }
})()

export default suggester;