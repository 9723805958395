import ViewComponent from '../../ViewComponent';
import { getCookie, formatPrice } from '../../helpers/helpers';
import BookmarkBtn from '../../submodules/bookmarkBtn/BookmarkBtn';
import 'justifiedGallery';
require('justifiedGallery/dist/css/justifiedGallery.min.css');

class PhotoItemView extends ViewComponent {
  constructor(model) {
    super();
    this._model = model;
    this.state = {
      in_cart: false
    };

    this.image = IMAGE;

    this.purchase = {
      type: 'photo',
      id: this.image.id,
      url: this.image.url,
      free: this.image.free
    };

    this.bookmarkBtn = new BookmarkBtn('#bookmark_btn');
    this.bookmarkBtn.init(this.image.id, 'photo');

    this.renderAdultContent();
  }

  init() {
    this.update();
    this.setupListeners();
    this.setupGallery();
    this.setupDescription();
    this.setupPurchaseOptions();
    this.checkIfImageLoaded('main_img', ".photo-container");
    this.togglePurchaseOption('download')
  }

  update(update = {}) {
    $('#buy').removeClass('faded');
    $('#save_info').removeClass('faded');
    if (this.state.in_cart) {
      $('#buy').removeClass("btn-primary").addClass('btn-info')
      $('#buy').html('<span class="glyphicon glyphicon-ok"></span> Kosárban')
    } else {
      $('#buy').addClass("btn-primary").removeClass('btn-info')
      $('#buy').html('<span class="glyphicon glyphicon-shopping-cart"></span> Kosárba')
    }

    $('.admin-btn').removeClass('disabled');
    Array.from($('.admin-btn')).forEach(btn => {
      let field = $(btn).attr('data-field');
      let active = field === 'disabled' || field === 'deleted' ? 'btn-danger' : 'btn-success';
      if (this.image[field]) {
        $(btn).removeClass('btn-default').addClass(active);
      } else {
        $(btn).removeClass(active).addClass('btn-default');
      }
    })

    if (typeof update['cHatterInformacio_str'] === 'string') {
      window.alert('Mentve!');
      $('#info').html(update['cHatterInformacio_str'])
    }

    if (update['comment']) {
      $('#comment_panel').html(`
        <p>
          Ön megjegyzést küldött be ehhez a képhez:
        </p>
        <div class="well well-sm mb-1">${update['comment']}</div>
        <p class="mb-0">Köszönjük!</p>
      `)
      $('#comment_panel').removeClass('faded');
    }


    this.showMargin();
  }

  renderAdultContent() {
    const isAdult = getCookie('adult') ?? false;

    if (isAdult) {
      this.disableAdultContent();
    } else {
      $("#adult_content_modal").modal('show')
    }
  }

  downloadPhoto(photo) {
    $("#photo_download_modal").modal("hide");

    const filename = $('#mti-download').data('filename') ?? $('#ldap-download').data('filename');
    const url = window.URL.createObjectURL(photo);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${filename}.jpg`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  renderDownloadError(error) {
    $('#photo_download_modal .error_container').removeClass('d-none');
    $('#photo_download_modal .modal-footer').removeClass('d-none');
    $('#photo_download_modal .download_container').addClass('d-none');
    const alertBox = $('#photo_download_modal .error_container .alert');

    if (error?.status === 403) {
      alertBox.html("A letöltések mennyisége meghaladta a csoport havi kvótáját!")
      return;
    }

    alertBox.html("A letöltés során hiba lépett fel!");
  }

  setupGallery() {
    $(".photolist_recommended").justifiedGallery({
      rowHeight: 150,
      margins: 6,
    });

    $(".photolist_related").justifiedGallery({
      rowHeight: 150,
      margins: 6,
    });
  }

  setupListeners() {
    $('#type_select [data-value]').click(e => {
      let target = $(e.target).attr('data-value');
      this.togglePurchaseOption(target);
    })

    $('[name=paper-select], [name=canvas-select]').click(e => {
      let value = $(e.target).val();
      let type = $(e.target).attr('name').split('-')[0];
      this.selectPrintMaterial(type, value)
    })

    $('.download-options [type=radio]').click(e => {
      let value = $(e.target).val();
      if (value) {
        this.updatePurchase({
          size: value
        })
      }
    })

    $('.material-size [type=radio]').click(e => {
      let value = $(e.target).val();
      if (value) {
        this.updatePurchase({
          dimensions: value
        })
      }
    })

    $("#buy").click(() => {
      $('#buy').addClass('faded');
      $.ajax({
        url: SITE_URL + 'images/available/' + this.image.url,
        method: 'GET',
        success: response => {
          console.log(response)
          if (response['isAvailable']) {
            this.emit('purchase', this.purchase);
          } else {
            let answer = confirm('A kép technikai okokból sajnos nem elérhető, Kérem keresse fel a helpdesket az OK gombra kattintva!');
            if (answer) {
              window.location.href = SITE_URL + '/helpdesk';
            }
            $('#buy').removeClass('faded');
          }
        }
      })
    })

    $('.fs-toggle').click(() => {
      this.openFullSize()
    })

    $('.photo-modal').click(() => {
      $('.photo-modal').removeClass('toggled');
    })

    $('[name=info]').on('input', e => {
      let value = $(e.target).val();
      if (value.length > 4096) {
        value = value.substring(0,4096);
      }
      console.log(value)
      $(e.target).val(value);
    })

    $('#comment_form [name="comment"]').on('input', e => {
      let val = $(e.target).val();
      if (val.length > 1000) {
        $(e.target).val(val.substring(0, 1000));
      }
    })


    $('#comment_form').on('submit', e => {
      e.preventDefault();
      let comment = $('[name="comment"]').val()
      if (!comment.length) return;
      if (comment.length > 1000) {
        window.alert("Túl hosszú komment, kérjük rövidítse le 1000 karakterre.")
        return;
      }
      $('#comment_panel').addClass('faded');
      this._model.saveComment(this.image.id, comment)
    })

    $('#technical_details_toggle').on('click', function(e) {
      $(this).toggleClass('active');
      $('#technical_details').toggleClass('active');
    });

    $('#mti-download').on('click', (e) => {
      const photoId = $('#mti-download').data('id');
      this._model.downloadPhoto(photoId);

      $('#photo_download_modal .error_container').addClass('d-none');
      $('#photo_download_modal .modal-footer').addClass('d-none');
      $('#photo_download_modal .download_container').removeClass('d-none');

      $("#photo_download_modal").modal("show");
    });

    $('#ldap-download').on('click', (e) => {
      const photoId = $('#ldap-download').data('id');
      this._model.downloadPhoto(photoId, 'ldap');

      $('#photo_download_modal .error_container').addClass('d-none');
      $('#photo_download_modal .modal-footer').addClass('d-none');
      $('#photo_download_modal .download_container').removeClass('d-none');

      $("#photo_download_modal").modal("show");
    });

    $("#adult-modal-yes").click(() => {
        this.disableAdultContent();

        document.cookie = "adult=true; path=/";

        $("#adult_content_modal").modal('hide');
    });

    $("#adult-modal-no").click(() => {
      $("#adult_content_modal").modal('hide');
    });
  }

  setupDescription() {
    let height = $('.photo-page-description').height();
    $('.photo-page-description').removeClass('init');
    if (height > 150) {
      $('.photo-page-description').addClass('limited');
      $('.photo-page-description-toggle').show();
      $('.photo-page-description-toggle').click(() => {
        $('.photo-page-description').toggleClass('limited');
      })
    }
  }

  disableAdultContent() {
    $('.photo-container img').removeClass('photo-item-blur-image-mid');
    $('.photo-container .photo-item-blur-overlay').addClass('d-none');

    $('#photolist_recommended img').removeClass('photo-item-blur-image');
    $('#photolist_recommended .photo-item-blur-overlay').addClass('d-none');

    $('#photolist_related img').removeClass('photo-item-blur-image');
    $('#photolist_related .photo-item-blur-overlay').addClass('d-none');
  }

  setupPurchaseOptions() {
    if (this.state.in_cart) {
      if (this.purchase.size === 'paper' || this.purchase.size === 'canvas') {
        this.togglePurchaseOption(this.purchase.size);
      } else {
        this.togglePurchaseOption('download');
      }
      return;
    }

    if (this.query.select && this.query.select === 'c') {
      this.togglePurchaseOption('canvas');
    } else if (PRICES.canvas) {
      this.togglePurchaseOption('canvas');
    } else {
      this.togglePurchaseOption('download');
    }

    if (getCookie('isCorporate')) {
      this.togglePurchaseOption('download')
    }
  }

  selectPrintMaterial(type, material) {
    let dimensions = this.purchase.material === material && this.purchase.dimensions ? this.purchase.dimensions : $(`.${type}-options .material-sizes input[type=radio]`).first().val();
    $(`.material-size`).hide();
    $(`#${material}.material-size`).show();
    $(`#${material} [value=${dimensions}]`).prop('checked', true);
    this.updatePurchase({
      size: type,
      dimensions,
      material,
    })
  }

  togglePurchaseOption(option) {
    $(`#type_select [data-value]`).removeClass('btn-primary');
    $(`#type_select [data-value]`).addClass('btn-default');
    $(`#type_select [data-value=${option}]`).removeClass('btn-default');
    $(`#type_select [data-value=${option}]`).addClass('btn-primary');
    $(`.purchase-options`).hide();

    if ((option === 'canvas' && !PRICES['canvas']) || (option === 'paper' && !PRICES['paper'])) {
      let texts = {
        canvas: 'vásznon',
        paper: 'papírképen',
      }
      $('#buy').addClass('hidden');
      $('#total').addClass('hidden');
      $('#request').removeClass('hidden');
      $('#request p').html(`Ez a kép speciális mérete/arányai miatt csak igénylés útján érhető el ${texts[option]}.`)
      return;
    } else {
      $('#buy').removeClass('hidden');
      $('#total').removeClass('hidden');
      $('#request').addClass('hidden');
    }

    $(`.${option}-options`).show();
    if (option !== 'download') {
      let material = this.purchase.size === option && this.purchase.material ? this.purchase.material : $(`.${option}-options .material-options input[type=radio]`).first().val();
      $(`.${option}-options [value=${material}]`).prop('checked', true);
      this.selectPrintMaterial(option, material);
    } else {
      let license = this.purchase.size && this.purchase.size !== 'canvas' && this.purchase.size !== 'paper' ? this.purchase.size : 'original';
      $(`.${option}-options [value=${license}]`).prop('checked', true);
      this.updatePurchase({
        size: license,
      })
    }
  }

  updatePurchase(update = {}) {
    let purchase = JSON.parse(JSON.stringify(this.purchase));
    Object.keys(update).forEach(key => {
      purchase[key] = update[key];
      if (key === 'size' && update[key] !== 'canvas' && update[key] !== 'paper') {
        purchase['dimensions'] = null;
        purchase['material'] = null;
      }
    })
    this.purchase = purchase;
    if (this.state.in_cart) this.emit('purchase', this.purchase)
    this.displaySum();
  }

  getSum() {
    if (this.purchase.size === 'canvas' || this.purchase.size === 'paper') {
      if (!this.purchase.dimensions) return false;
      let width = parseInt(this.purchase.dimensions.split('x')[0]);
      let price = PRICES[this.purchase.size][this.purchase.material]['prices'].find(item => item.w == width).price;
      return price + (this.image?.free ? 0 : CONFIG.prices.photo['original'].price);
    } else {
      if (this.purchase.size === "extended") {
        return CONFIG.prices.photo[this.purchase.size].price
      }
      return this.image?.free ? 0 : CONFIG.prices.photo[this.purchase.size].price
    }
  }

  displaySum() {
    let price = this.getSum();

    if (price === false) return;
    $('#item_sum').html(formatPrice(price) + ' HUF')
  }

  showMargin() {
    if (this.state.in_cart && this.purchase.size === 'canvas') {
      let margin = (($('.photo-container').width() / parseInt(this.purchase.dimensions.split('x')[0])) * 3);
      $('.canvas-margin, .canvas-margin-tip').show();
      $('.photo-container .canvas-margin').css("border", `${margin}px solid rgba(255, 173, 173, 0.4)`);
    } else {
      $('.canvas-margin, .canvas-margin-tip').hide();
      $('.photo-container .canvas-margin').css("border", `${0}px solid rgba(255, 173, 173, 0.4)`);
    }
  }

  checkIfImageLoaded(target, container) {
    let loadedCheck = setInterval(function () {
      let loaded = document.getElementById(target).complete;
      if (loaded) {
        $(container).removeClass('loading');
        clearInterval(loadedCheck);
      }
    }, 100);
  }

  openFullSize() {
    $('.photo-modal').addClass('toggled');
    $('.photo-modal .img-container').addClass('loading');
    let url = $('.photo-modal .img-responsive').attr('data-src')
    let small_url = $('.photo-modal .img-small').attr('data-src')
    $('.photo-modal .img-small').attr('src', small_url);
    $('.photo-modal .img-responsive').attr('src', url);
    this.checkIfImageLoaded('modal_img', '.photo-modal .img-container');
  }
}

export default PhotoItemView;
