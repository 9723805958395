import Model from './PhotoItemModel';
import View from './PhotoItemView';
import CartController from '../../CartController'

class Controller extends CartController {
  constructor(model, view) {
    super();
    this._model = model;
    this._view = view;

    let in_cart = this.cart.idInCart(IMAGE.id)

    this._view.setState({in_cart: in_cart ? true : false});

    if (in_cart) this._view.updatePurchase(in_cart);

    this._view.init();


    this._view.on('purchase', purchase => {
      if (this.cart.itemInCart(purchase)) {
        this.cart.remove(purchase.id);
      } else {
        if (this.cart.idInCart(purchase.id)) {
          this.cart.switch(purchase.id, purchase);
        } else {
          this.cart.add(purchase);
        }
      }
    })

    this._view.on('stateUpdated', (prevState) => {
      this._view.update();
    })

    this.cart.on('addedToCart', newCart => {
      let in_cart = newCart.find(item => item.id === IMAGE.id);
      this._view.setState({in_cart})
    })

    this.cart.on('removedFromCart', newCart => {
      let in_cart = newCart.find(item => item.id === IMAGE.id);
      this._view.setState({in_cart})
    })
    
    this.cart.on('update', newCart => {
      let in_cart = newCart.find(item => item.id === IMAGE.id);
      this._view.setState({in_cart})
    })

    this._model.on('savedComment', comment => {
      this._view.update(comment);
    })

    this._model.on('imageDownloaded', blob => {
      this._view.downloadPhoto(blob);
    })

    this._model.on('imageDownloadError', (error) => {
      this._view.renderDownloadError(error);
    });
  }
}

const PhotoItem = (function() {
  return {
    init: () => {
      const model = new Model();
      const view = new View(model);
      const controller = new Controller(model, view);
    }
  }
})()

export default PhotoItem;