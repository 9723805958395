import Component from '../../Component';

class BookmarkBtnView extends Component {
  constructor(model, container, type) {
    super();
    this._model = model;
    this.state = {
      collections: null,
      id: null
    };
    
    this.container = container;
    this.type = type;

    if ($(this.container).length) {
      this._model.getCollections()
    }

    $(this.container).on('click', e => {
      this.handleClick(e);
    })
  }

  handleClick(e) {
    if ($(e.target).is('[data-collection]')) {
      e.stopPropagation();
      let url = $(e.target).attr('data-collection');
      let collection = this.state.collections.find(c => c.url === url);
      let action
      if (collection) {
        let in_collection = collection.items.find(item => item.items_id === this.state.id);
        action = in_collection ? 'delete' : 'save';
      } else {
        action = save;
      }
      this._model[action](this.state.id, url, this.state.type);
    }
  }

  updateList() {
    let list = this.renderList();
    $('.dropdown-menu.bookmark-btn-dropdown').html(list);
  }

  renderList() {
    return this.state.collections.map(c => {
      let in_collection = c.items.find(item => item.items_id === this.state.id);
      return `
      <li>
        <a data-collection=${c.url}>
          ${c.title} ${in_collection ? '<i class="fas fa-check text-success"></i>' : '<i class="fas fa-plus text-muted"></i>'}
        </a>
      </li>
      `
    }).join('') + `
    <li role="separator" class="divider"></li>
    <li><a href="${SITE_URL + 'bookmarks/collections'}" target="_blank">Mentett tartalmak <i class="fas fa-arrow-right text-primary"></i></a></li>
    `;
  }

  render(prevState) {
    if (this.state.collections && this.state.id) {
      let collection_list = this.renderList();
      let btn = `
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Mentés <span class="caret"></span>
          </button>
          <ul class="dropdown-menu bookmark-btn-dropdown">
            ${collection_list}
          </div>
        </div>
      `
      $(this.container).html( btn )

    } else if (!prevState.collections && !this.state.collections) {

    }
  }
}

export default BookmarkBtnView;