import ViewComponent from '../../ViewComponent';
import 'justifiedGallery';
require('justifiedGallery/dist/css/justifiedGallery.min.css');

class PhotoMainView extends ViewComponent {
  constructor(model) {
    super();
    this._model = model;
    this.state = {};

    this.loading = false;
    this.actionLoading = [];

    this.setupListeners()
  }

  renderNumFound(numFound) {
    let content = '';
    if (numFound) {
      content = `
      <div class="col-sm-12">
        <p><b>${numFound}</b> találat
      </div>`
    } else {
      content = `
      <div class="col-sm-4"></div>
        <div class="col-sm-4" style="text-align: center">
          <h1 style="color: #d1d1d1; font-size: 100px"><i class="fas fa-film"></i></h1>
          <h5>Nincs találat</h5>
          <p>Amennyiben nem találja amit keres, <br>elképzelhető, hogy olyan képet próbált keresni, amihez előfizetés vagy más regisztráció szükséges.</p>
          <a class="btn btn-primary " href="${SITE_URL + 'requests/edit'}">Igénylés indítása</a>
        </div>
      <div class="col-sm-4"></div>`
    }
    $('#numFound').html(content);
  }

  renderImages(results) {
    const images = results?.data?.response?.docs || [];

    images?.forEach(img => {
      let isBlured = img.terrible;
      let isAdult = img.adult;

      let description = `${img?.city && img.city !== "-" ? img.city : (img?.country || "")}, ${img?.created_date_txt || ""}\n${img?.highlighted_description || img?.description || ""}`;

      $('#photolist').append(`
        <a
          href="${SITE_URL + 'photobank/item/' + img.url + (this.query.type === 'c' ? '?select=c' : '')}"
          class="${img.deleted ? 'img-banned' : ''} ${img.disabled ? 'img-disabled' : ''} ${isBlured ? 'img-terrible' : ''}"
          data-id="${img.id}"
          target="_blank"
        >
          ${USER.r >= 2 ? `
            <div class="photo-list-item-fade"></div>

            ${img.deleted ? `
            <div class="photo-list-item-banned">
              <i class="fas fa-times"></i>
              <p>Törölt tartalom.</p>
            </div>
            ` : ''}

            ${img.disabled ? `
            <div class="photo-list-item-disabled">
              <i class="far fa-eye-slash"></i>
              <p>Nem publikálható tartalom.</p>
            </div>
            ` : ''}
          ` : ""}
          <div class="${USER.r >= 2 ? 'photo-item-overlay' : 'photo-item-overlay-bottom'}"></div>
          ${isBlured || isAdult ? `
            <div class="photo-item-blur-overlay">
                ${isAdult ? `<div class="photo-item-adult-overlay">18</div>` : ""}
            </div>
          ` : "" }
          <img alt='${description}' src="data:image/jpg;base64, ${img.imgSrc}" class="${isBlured || isAdult ? 'photo-item-blur-image' : ""}"/>
        </a>
        `)
    })

    if (results.page == 0) { // initial
      $("#photolist").justifiedGallery({
        rowHeight: 200,
        margins: 6,
        maxRowHeight: 200,
      });
    } else {
      $('#photolist').justifiedGallery('norewind');
    }

    $("#photolist a").off('mouseenter mouseleave');

    $("#photolist a").hover(
      function () {
        const caption = $(this).find('.caption');
        caption.addClass("caption-visible");
        caption.removeClass('caption-expanded');

        caption.add('timer', setTimeout(function () {
          caption.addClass('caption-expanded');
        }, 1000))
      },
      function () {
        const caption = $(this).find('.caption');
        caption.removeClass('caption-expanded');
        caption.removeClass('caption-visible');

        clearTimeout(caption.data('timer'));
      }
    );
  }

  imgLoader(id, action) {
    if (action === 'on') {
      this.actionLoading.push(id);
      $(`[data-id=${id}]`).addClass('loading');
      $(`[data-id=${id}]`).append(`
        <div class="photo-list-item-loader">
          <i class="fas fa-spin fa-spinner"></i>
        </div>
      `)
    } else {
      this.actionLoading.splice(this.actionLoading.findIndex(i => i === id), 1);
      $(`[data-id=${id}]`).removeClass('loading');
      $(`[data-id=${id}] .photo-list-item-loader`).remove();
    }
  }

  setupListeners() {
    $(window).on('scroll', () => {
      let top = $(window).scrollTop();
      if (top < 20) {
        $('.arrow-up').addClass('noshow');
      }
      if (top > 100) {
        $('.arrow-up').removeClass('noshow');
      }

      if (top + $(window).height() > $(document).height() - 400 && !this.loading) {
        this.loading = true
        this._model.getPage();
      }
    })

    $('#photolist').justifiedGallery().on('jg.complete', (e) => {
      this.loading = false
    });

    $('.arrow-up').click(function() {
      $("html, body").stop().animate({scrollTop:0}, 500, 'swing', function() {

      });
    })

    $(".handle").click(function() {
      $('.footer').toggleClass('toggled');
    })
  }
}

export default PhotoMainView;
