import App from '../app';
import PhotoItem from './photoItem/PhotoItem';
import PhotoMain from './photoMain/PhotoMain';
import search from '../search/search';

import '../../css/photobank.css';

const Modules = {
    search,
    PhotoItem,
    PhotoMain,
}

App.init(Modules);
