import ModelComponent from '../../ModelComponent';

class PhotoItemModel extends ModelComponent {
  constructor() {
    super();
    this.state = {};
    this.loading = false;
  }

  saveComment(items_id, comment) {
    if (this.loading) return;
    this.loading = true;

    $.ajax({
      method: 'POST',
      url: SITE_URL + 'photobank/comment',
      data: {
        items_id,
        comment
      },
      success: response => {
        response = JSON.parse(response)
        this.loading = false
        this.emit('savedComment', {comment})
      },
      error: error => {
        this.loading = false;
        console.log(error);
      },
    })
  }

  downloadPhoto(id, type= 'mti') {
    if (this.loading) return;
    this.loading = true;

    const url = type === 'mti' ? `api/photobank/v2/download/${id}` : `api/photobank/v2/download_ldap/${id}`

    $.ajax({
      method: 'GET',
      url: SITE_URL + url,
      xhrFields: {
        responseType: 'blob'
      },
      success: response => {
        this.loading = false
        this.emit('imageDownloaded', response);
      },
      error: error => {
        this.loading = false;
        this.emit('imageDownloadError', error);
        console.log(error);
      },
    })
  }
}

export default PhotoItemModel;
