import Model from './BookmarkBtnModel';
import View from './BookmarkBtnView';

class Controller {
  constructor(model, view) {
    this._model = model;
    this._view = view;

    this._view.on('stateUpdated', prevState => {
      if (prevState.collections) {
        this._view.updateList()
      } else {
        this._view.render(prevState)
      }
    })

    this._model.on('gotCollections', collections => {
      this._view.setState({collections})
    })
    this._model.on('savedItem', collections => {
      this._model.getCollections()
    })
    this._model.on('deletedItem', collections => {
      this._model.getCollections()
    })
  }
}

class BookmarkBtn {
  constructor(selector) {
    this._model = new Model();
    this._view = new View(this._model, selector);
    this._controller = new Controller(this._model, this._view);
  }

  init(id, type) {
    this._view.setState({id, type});
  }
}

export default BookmarkBtn;