import Component from '../../Component';

class BookmarkBtnModel extends Component {
  constructor() {
    super();
    this.state = {};
  }

  getCollections() {
    $.ajax({
      url: SITE_URL + 'bookmarks/get_collections',
      method: 'GET',
      success: response => {
        response = JSON.parse(response);
        response = response.filter(function (item) {
          return item.url !== 'news_bank' && item.url !== 'mkk'
        })
        console.log(response)
        if (response.length) {}
        this.emit('gotCollections', response);
      }
    })
  }

  save(id, collection, type) {
    $.ajax({
      method: 'GET',
      url: SITE_URL + 'bookmarks/save_item?item_id=' + id + '&collection_url=' + collection + "&type=" + type,
      success: response => {
        
        console.log(response)
        this.emit('savedItem', response);
      }
    })
  }

  delete(id, collection, type) {
    $.ajax({
      method: 'POST',
      data: {
        item_id: id,
        collection_url: collection
      },
      url: SITE_URL + 'bookmarks/delete_item',
      success: response => {
        
        console.log(response)
        this.emit('deletedItem', response);
      }
    })
  }
}

export default BookmarkBtnModel;