import ModelComponent from '../../ModelComponent';

class PhotoMainModel extends ModelComponent {
  constructor() {
    super();
    this.state = {
      page: 0,
    };

    this.loading = false;
  }

  getPage() {
    if (this.loading) return;
    this.loading = true;
    let query = JSON.parse(JSON.stringify(this.query));

    if (Object.keys(query).length) {
      if (query && query.type === 'r') {
        query.type = 'recent';
      } else if (query && query.type === 'c') {
        query.type = 'canvas';
      } else if (query && (query.query || query.min || query.max || query.category || query.filters)) {
        query.type = 'search';
      }
    } else {
      query.type = 'recommended'
    }

    console.log(query);
    $.ajax({
      url: SITE_URL + 'api/photobank/v2/search',
      method: 'GET',
      data: {
        page: this.state.page,
        limit: 25,
        type: query.type,
        query: query
      },
      success: response => {
        this.loading = false
        // response = JSON.parse(response);
        response.page = this.state.page
        console.log(response)
        this.emit('gotPage', response);
        if (response?.data?.response?.docs?.length) {
          this.setState({page: this.state.page + 1});
        }
      },
      error: error => {
        this.emit('gotPage', error);
      }
    })
  }
}

export default PhotoMainModel;